import React, {useState, useEffect, useContext} from "react"
import {Link} from 'gatsby';
import Layout from "../../components/layout"
import {Container, Table, Button, Row, Col, Form, FormControl, Alert} from 'react-bootstrap'
import SEO from "../../components/seo";
import Loader from '../../components/loading';
import GenericPagination from '../../components/genericPagination';
import AddCustomer from '../../components/customers/addCustomer';
import StandardModal from '../../components/modal';
import {replaceSpaceInPhoneNumber} from '../../helpers/string';

import Fetch from "../../helpers/fetch";
import { isAdmin } from '../../helpers/roles'
import constants from '../../helpers/constants';
import {emptyUpperBody, emptyLowerBody,emptyUpperBodyFemale } from '../../helpers/extractMeasurement';
import { 
  DDMMYYYYFormat, 
} from '../../helpers/dateTime';
import { getMeasurementError, measurementLogic, getMeasurementInfo } from "../../helpers/measurementValidation";
import {  getDecodedToken } from '../../components/layout';

const CustomerSummary = ({location}) => {
  
  const emptyMeasurement = {
    upperBody: emptyUpperBody,
    upperBodyFemale: emptyUpperBodyFemale,
    lowerBody: emptyLowerBody,
    posture:'',
    consultant_name: '',
    shtCollar: '',
    shtShoulder: '',
    shtChest: '',
    shtWaist: '',
    shtHip: '',
    shtSleeveLength: '',
    shtCuff: '',
    shtArmhole: '',
    shtShoulderSlope: '',
    shtChestFront: '',
    shtChestBack: '',
    shtDoubleCuff: '',
    shtTuckOutLength: '',
    shtShortSleeveLength: '',
    shtShortSleeveCuff: '',
    shtPreferredFit: '',
    shtLength: '',
    shtElbow: '',
    shtBicep: '',
    pantsWaist: '',
    pantsHip: '',
    pantsCrotch: '',
    pantsThigh: '',
    pantsKnee: '',
    pantsCuff: '',
    pantsLowerCuff: '',
    pantsLength: '',
    shortPantsWaist: '',
    shortPantsHip: '',
    shortPantsCrotch: '',
    shortPantsThigh: '',
    shortPantsCuff: '',
    shortPantsLength: '',
    pantPreferredFit: '',
    jcktShoulder: '',
    jcktChest: '',
    jcktWaist: '',
    jcktHip: '',
    jcktSleeveLength: '',
    jcktCuff: '',
    jcktElbow: '',
    jcktLength: '',
    jcktBicep: '',
    jacketArmhole: '',
    jacketShoulderSlope: '',
    jacketChestFront: '',
    jacketChestBack: '',
    jacketPreferredFit: '',
    vestBackLength: '',
    vestShoulder: '',
    vestChest: '',
    vestWaist: '',
    vestHip: '',
    vestPreferredFit: '',
    femaleShoulderToBust: '',
    femaleBustPoints: '',
    femaleBustToWaist: '',
    femaleWaistToHip: '',
    femaleBackLength: '',
    femaleSkirtWaist: '',
    femaleSkirtHip: '',
    femaleSkirtHipHeight: '',
    femaleSkirtLength: '',
    femaleSkirtOpening: '',
  }
  const emptyEditItem = {
    id: '',
    name:'',
    email: '',
    emailCc: '',
    countryId: '1',
    countryName: 'SINGAPORE',
    phonePrefix: '+65',
    phone: '',
    howYouKnowAboutUs:'',
    howYouKnowAboutUsOthersRemarks:'',
    occupation: '',
    dob:'',
    gender:'',
    postal:'',
    unitNo:'',
    address:'',
    remarks:'',
    smsNotification: true,
    emailNotification: true,
    uplineReferralCode: '',
    personalReferralCode: '',
    ...emptyMeasurement
  }

  const emptyGarmentMeasurement = {
    shirt:{
      collar:"",
      shoulder:"",
      chest:"",
      waist:"",
      hip:"",
      tuckInLength:"",
      tuckOutLength:"",
      armhole:"",
      leftSleeve:"",
      rightSleeve:"",
      bicep:"",
      elbow:"",
      leftCuff:"",
      rightCuff:"",
      leftDoubleCuff:"",
      rightDoubleCuff:"",
      leftShoulderSlope:"",
      rightShoulderSlope:"",
      ssSleeveLength:"",
      ssCuff:"",
      jacketLength: "",
      backVestLength: "",
    },
    pants:{
      waist: "",
      hip: "",
      thigh:"",
      knee:"",
      cuff:"",
      leftLpLength:"",
      rightLpLength:"",
      uCrotch:"",
      frontCrotch:"",
      backCrotch:"",
      spCuff:"",
      spLength:"",
      skirtWaist:"",
      skirtHip:"",
      skirtLength:"",
      skirtOpening:"",
    }
  }
  const [showLoader, setShowLoader] = useState(false);
  const [customersArray, setCustomersArray] = useState([])
  const [pagination, setPagination] = useState({});
  const [rowLimit, setRowLimit] = useState(25);
  const [showSuccessAlert, setShowSuccessAlert] = useState(false);
  const [successAlertMsg, setSuccessAlertMsg] = useState('');
  const [showFailAlert, setShowFailAlert] = useState(false);
  const [failAlertMsg, setFailAlertMsg] = useState('');
  const [page, setPage] = useState(1)
  const [editItem, setEditItem] = useState(emptyEditItem);
  const [measurements, setMeasurements] = useState(emptyMeasurement);
  const [finishedGarmentMeasurement, setFinishedGarmentMeasurement] = useState(emptyGarmentMeasurement);
  const [editCustomer, setEditCustomer] = useState(false);
  const [showAddCustomer, setShowAddCustomer] = useState(false);
  const [showSummaryTable, setShowSummaryTable] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [deleteId, setDeleteId] = useState(0);
  const [countryCodes, setCountryCodes] = useState([]);
  const [occupations, setOccupations] = useState([]);
  const [phone, setPhone] = useState('');
  const [custId, setCustId] = useState(location && location.state && location.state.custId ? location.state.custId : '');
  const [oldCustId, setOldCustId] = useState('')
  const [custName, setCustName] = useState('')
  const [tempPwd, setTempPwd] = useState('');
  const [consultantReferralCode, setConsultantReferralCode] = useState('')
  const [decodedToken, setDecodedToken] = useState(getDecodedToken());
  
  const getConsultantReferralCode = () => {
    const url=`${constants.BACKEND_URL}/consultants/id/${decodedToken.custId}`
    Fetch.get(url).then(res => {
     setConsultantReferralCode(res.referralCode)
    })
  }

  const getCustomers = () => {
    let queryString = '';
    let queryParams = [];
    let url = `${constants.BACKEND_URL}/customers`
    
    if(custId) {
      queryParams.push(`custId=${custId}`)
    }

    if(oldCustId) {
      queryParams.push(`oldCustId=${oldCustId}`)
    }

    if(custName) {
      queryParams.push(`custName=${custName}`)
    }
    
    if(phone) {
      queryParams.push(`phone=${phone}`)
    }
    
    if(page) {
      queryParams.push(`page=${page}`)
    }
    
    if(queryParams.length > 0) {
      queryString = `?${queryParams.join('&')}`
    }
    
    setShowLoader(true)
    Fetch.get(`${url}${queryString}`).then(data => {
      setCustomersArray(data.data);
      setPagination(data.pagination);
      setShowLoader(false)
    })
    
  }

  const searchPhone = (e) => {
    const sanitizedPhone = replaceSpaceInPhoneNumber(e.target.value)
    setPhone(sanitizedPhone);
    setPage(1);
  }

  const searchCustId = (e) => {
    setCustId(e.target.value);
    setPage(1);
  }

  const searchOldCustId = (e) => {
    setOldCustId(e.target.value);
    setPage(1);
  }

  const searchCustName = (e) => {
    setCustName(e.target.value);
    setPage(1);
  }

  useEffect(() => {
    getConsultantReferralCode()
    if (phone || custId || oldCustId || custName ) {
      const timeOutId = setTimeout(() => getCustomers(), constants.SEARCH_TIMEOUT_IN_MS);
      return () => clearTimeout(timeOutId);
    } else {
      getCustomers()
    }
  },[page, phone, custId, oldCustId, custName])

  const handleItemChange = (e) => {
    const changedProperty = {}
    const value = e.target.value;
    const property = e.target.dataset.property

    if (e.target.dataset && e.target.dataset.section) {
      const section = e.target.dataset.section;
      const obj = {}
      changedProperty[section] = {...editItem[section]}
      obj[property] = value
      changedProperty[section] = {...changedProperty[section], ...obj};
    } else {
      switch (property) {
        case 'smsNotification':
        case 'emailNotification':
          changedProperty[property] = !editItem[property];
          break;
        case 'countryName':
          changedProperty['countryId'] = e.target.dataset.countryId;
          break;
        case 'uplineReferralCode':
          changedProperty['uplineReferralCode'] = value;
          break;
        default:
          changedProperty[property] = value;
          break;
      }        
    }
    
    
    setEditItem({...editItem, ...changedProperty});
  }

  const handleAddCustomerClick = () => {
    setShowAddCustomer(true);
    setShowSummaryTable(false);
    const emptyEditItemCopy = {...emptyEditItem}
    setEditItem({...emptyEditItemCopy, ...{ uplineReferralCode: consultantReferralCode} })
    setMeasurements(emptyMeasurement);
  }

  const saveEditItem= (e) => {
    const url = `${constants.BACKEND_URL}/customers`
    const editItemCopy = {...editItem}  
    editItemCopy.measurements = JSON.stringify(measurements);
    editItemCopy.finishedGarmentMeasurement = JSON.stringify(finishedGarmentMeasurement);

    setShowLoader(true);
    if(!editCustomer) {
      Fetch.post(url, JSON.stringify(editItemCopy))
      .then((data) => {
        if (data.error) {
          setShowFailAlert(true);
          setFailAlertMsg([`${data.error} - ${data.message ? data.message: ''}`])
          setShowLoader(false);
          window.scrollTo(0, 0)
          return;
        }
        setShowSummaryTable(true);
        getCustomers();
        setShowLoader(false);
        setShowFailAlert(false);
        setShowAddCustomer(false);
        if (data.tempPwd) setTempPwd(data.tempPwd);
      })
      .catch((error) => {
        // console.log({error});
      })

    } else {
      Fetch.put(`${url}/${editItemCopy.id}`, JSON.stringify(editItemCopy))
      .then((data) => {
        if (data.error) {
          setShowFailAlert(true);
          setFailAlertMsg([`${data.error}`])
          setShowLoader(false);
          window.scrollTo(0, 0)
          return;
        }
        setShowSummaryTable(true);
        getCustomers();
        setEditCustomer(false);
        setShowFailAlert(false);
        // setShowLoader(false);
        setShowAddCustomer(false);
      })

    }
  }

  const cancelEditItem = () => {
    setShowSummaryTable(true);
    setShowAddCustomer(false);
    setEditCustomer(false);
    setEditItem(emptyEditItem);
  }

  const handleDeleteClick = (e) => {
    setDeleteId(e.target.dataset.deleteId);
    setShowModal(true)
  }

  const handleDeleteCustomerConfirm = () => {
    const url=`${constants.BACKEND_URL}/customers/${deleteId}`
    Fetch.delete(url).then(data => {
     setShowModal(false)
     getCustomers();
    })
  }

  const handleDeleteCustomerClose = () => {
    setShowModal(false);
  }

  const handleClick = (e) => {
    const obj = JSON.parse(e.target.dataset.value);
    obj.dob = DDMMYYYYFormat(obj.dob, true);
    obj.emailNotification = obj.emailNotification === 1 ? true : false;
    obj.smsNotification = obj.smsNotification === 1 ? true : false;
    if (obj && obj.measurements) {
      const measurements = JSON.parse(obj.measurements);
      if (measurements && measurements.upperBody) {
        obj.upperBody = measurements.upperBody
      }
      if (measurements && measurements.upperBodyFemale) {
        obj.upperBodyFemale = measurements.upperBodyFemale
      }
      if (measurements && measurements.lowerBody) {
        obj.lowerBody = measurements.lowerBody
      }
      if (measurements && measurements.posture) {
        obj.posture = measurements.posture
      }
      setMeasurements(measurements)
    }
    if (obj && obj.finishedGarmentMeasurement) {
      const finishedGarmentMeasurement = JSON.parse(obj.finishedGarmentMeasurement);
      if (finishedGarmentMeasurement && finishedGarmentMeasurement.upperBody) {
        obj.upperBody = finishedGarmentMeasurement.upperBody
      }
      if (finishedGarmentMeasurement && finishedGarmentMeasurement.upperBodyFemale) {
        obj.upperBodyFemale = finishedGarmentMeasurement.upperBodyFemale
      }
      if (finishedGarmentMeasurement && finishedGarmentMeasurement.lowerBody) {
        obj.lowerBody = finishedGarmentMeasurement.lowerBody
      }
      setFinishedGarmentMeasurement(finishedGarmentMeasurement)
    }
    obj.personalReferralCode = obj.referralCode ? obj.referralCode : '';
    setEditItem(obj);
    setEditCustomer(true);
    setShowAddCustomer(true);
    setShowSummaryTable(false);
  }

  const handleOnPaginationClick = (e) => {
    let target = e.target.dataset.goTo
    if (!target) {
      target = e.target.parentNode.dataset.goTo;
    }
    switch (target) {
      case "firstPage":
        setPage(1);
        break;
      case "prevPage":
        setPage(page-1);
        break;
      case "nextPage":
        setPage(page+1);
        break;
      case "lastPage":
        setPage(pagination.totalPages);
        break;
      default:
        setPage(target);
        break;
    }
  }

  const isStagingOrLocalServer = (location) => {
    if (location && location.hostname !== 'app.reddotbespoke.com') return true;
    return false;
  }

  const handlePostureChange = (e) => {
    const changedProperty = {}
    const { type, value } = e.target.dataset;
    const obj = { [type]:value}
    changedProperty['posture'] = {...measurements['posture'], ...obj  }
    
    setMeasurements({...measurements, ...changedProperty})
  }

  const handleMeasurementChange = (e) => {
    const changedProperty = {}
    const inputValue = e.target.value;
    const {property, unit} = e.target.dataset
    
    if (e.target.dataset && e.target.dataset.section) {
      const section = e.target.dataset.section;
      let obj = {}
      changedProperty[section] = {...measurements[section]}
      
      if (property === 'leftShoulderSlope' || property === 'rightShoulderSlope') {
        obj[property] = inputValue

      } else {
        obj[property] = {
            inch: unit === 'cm' ? Number(inputValue / 2.54).toFixed(2) : inputValue,
            cm: unit === 'inch' ? Number(inputValue * 2.54).toFixed(2) : inputValue,
            error: getMeasurementError(measurements, section, property, inputValue),
            info: getMeasurementInfo(measurements, section, property, inputValue),
        }
        
      }
      obj = measurementLogic(property, inputValue, changedProperty, section, obj, editItem.gender)
      changedProperty[section] = {...changedProperty[section], ...obj};

    } else {
      changedProperty[property] = inputValue;
    }
    setMeasurements({...measurements, ...changedProperty})
    

    const custDetailsCopy = {...editItem}
    custDetailsCopy.measurements = JSON.stringify({...measurements, ...changedProperty})
    setEditItem(custDetailsCopy)
    
  }

  const handleFinishedGarmentMeasurementChangeByKeyboard = (e) => {

    const changedProperty = {}
    const inputValue = e.target.value;
    const {property, unit} = e.target.dataset

    if (e.target.dataset && e.target.dataset.section) {
      const section = e.target.dataset.section;
      
      let obj = {}
      changedProperty[section] = {...finishedGarmentMeasurement[section]}
      
      if (property === 'leftShoulderSlope' || property === 'rightShoulderSlope') {
        obj[property] = inputValue

      } else {
        obj[property] = {
            inch: unit === 'cm' ? Number(inputValue / 2.54).toFixed(2) : inputValue,
            cm: unit === 'inch' ? Number(inputValue * 2.54).toFixed(2) : inputValue,
            error: getMeasurementError(finishedGarmentMeasurement, section, property, inputValue),
            info: getMeasurementInfo(finishedGarmentMeasurement, section, property, inputValue),
        }
        
      }
      obj = measurementLogic(property, inputValue, changedProperty, section, obj, editItem.gender)
      changedProperty[section] = {...changedProperty[section], ...obj};

    } else {
      changedProperty[property] = inputValue;
    }
    
    setFinishedGarmentMeasurement({...finishedGarmentMeasurement, ...changedProperty})
    const custDetailsCopy = {...editItem}
    custDetailsCopy.finishedGarmentMeasurement = JSON.stringify({...finishedGarmentMeasurement, ...changedProperty})
    setEditItem(custDetailsCopy)
    
  }

  const handleFinishedGarmentMeasurementChangeByNumpad = (_value, _section, _property, _unit) => {
    let value = '';
    
    switch (_value) {
      case 'backspace':
        if (_property === 'leftShoulderSlope' || _property === 'rightShoulderSlope') {
          value = finishedGarmentMeasurement[_section][_property].split('')  
        } else {
          value = finishedGarmentMeasurement[_section][_property][_unit].split('')
        }
        value.pop()
        value = value.join('')
        break;
      case 'clear':
        value = '';
        break;
      default:
        value = finishedGarmentMeasurement[_section] && 
        finishedGarmentMeasurement[_section][_property] &&
        finishedGarmentMeasurement[_section][_property][_unit] ? 
        finishedGarmentMeasurement[_section][_property][_unit].toString() + _value.toString() : 
        _value.toString();
        break;
    }
    
    updateFinishedGarmentMeasurement(value, _section, _property, _unit)
  }

  const handleMeasurementRemarksChange = (e) => {
    const measurementsCopy = {...measurements};
    measurementsCopy['upperBody']['remarks'] = e.target.value;
    setMeasurements(measurementsCopy)
  }

  const handleStepByStepMeasurementChange = (_value, _section, _property, _unit) => {
    let value = '';
    switch (_value) {
      case 'backspace':
        if (_property === 'leftShoulderSlope' || _property === 'rightShoulderSlope') {
          value = measurements[_section][_property].split('')  
        } else {
          value = measurements[_section][_property][_unit].split('')
        }
        value.pop()
        value = value.join('')
        break;
      case 'clear':
        value = '';
        break;
      default:
        value = measurements[_section] && 
        measurements[_section][_property] &&
        measurements[_section][_property][_unit] ? 
        measurements[_section][_property][_unit].toString() + _value.toString() : 
        _value.toString();
        break;
    }
    
    updateMeasurements(value, _section, _property, _unit)
  }

  const updateMeasurements = (inputValue, section, property, unit) => {
    const changedProperty = {}

    if (section) {
      // const section = e.target.dataset.section;
      let obj = {}
      changedProperty[section] = {...measurements[section]}

      if (property === 'leftShoulderSlope' || property === 'rightShoulderSlope') {
        obj[property] = inputValue

      } else {
        obj[property] = {
            inch: unit === 'cm' ? Number(inputValue / 2.54).toFixed(2) : inputValue,
            cm: unit === 'inch' ? Number(inputValue * 2.54).toFixed(2) : inputValue,
            error: getMeasurementError(measurements, section, property, inputValue),
            info: getMeasurementInfo(measurements, section, property, inputValue),
        }
      }
      obj = measurementLogic(property, inputValue, changedProperty, section, obj, editItem.gender)
      changedProperty[section] = {...changedProperty[section], ...obj};

    } else {
      changedProperty[property] = inputValue;
    }
    setMeasurements({...measurements, ...changedProperty})

  }

  const updateFinishedGarmentMeasurement = (inputValue, section, property, unit) => {
    const changedProperty = {}

    if (section) {
      // const section = e.target.dataset.section;
      let obj = {}
      changedProperty[section] = {...finishedGarmentMeasurement[section]}

      if (property === 'leftShoulderSlope' || property === 'rightShoulderSlope') {
        obj[property] = inputValue

      } else {
        obj[property] = {
            inch: unit === 'cm' ? Number(inputValue / 2.54).toFixed(2) : inputValue,
            cm: unit === 'inch' ? Number(inputValue * 2.54).toFixed(2) : inputValue,
            error: getMeasurementError(finishedGarmentMeasurement, section, property, inputValue),
            info: getMeasurementInfo(finishedGarmentMeasurement, section, property, inputValue),
        }
      }
      obj = measurementLogic(property, inputValue, changedProperty, section, obj, editItem.gender)
      changedProperty[section] = {...changedProperty[section], ...obj};

    } else {
      changedProperty[property] = inputValue;
    }
    setFinishedGarmentMeasurement({...finishedGarmentMeasurement, ...changedProperty})

  }

  return (
    <Layout pageInfo={{ pageName: "customersSummary" }}>
        <Loader show={showLoader}></Loader>
        <SEO title="Customers Summary" />
        <Row>
          <Col className="text-center">
            <h5>Customers Summary</h5>
          </Col>
        </Row>
        { showSummaryTable && 
        <Container className="text-center" >
          {isStagingOrLocalServer(location) && tempPwd &&
            <Alert variant="info">Customer Temp Pwd is: {tempPwd}</Alert>
          }
          <Row>
            <Col>
              <Alert variant="success" dismissible onClose={() => setShowSuccessAlert(false)} show={showSuccessAlert}>{successAlertMsg}</Alert>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col>
              <Form.Group as={Row} >
                <Form.Label  column sm={3}>Cust Id</Form.Label>
                <Col>
                  <FormControl
                    type="text"
                    placeholder="Search..." 
                    value={custId}               
                    onChange={searchCustId}
                  />
                </Col>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group as={Row} >
                <Form.Label  column sm={3}>Old Cust Id</Form.Label>
                <Col>
                  <FormControl
                    type="text"
                    placeholder="Search..." 
                    value={oldCustId}               
                    onChange={searchOldCustId}
                  />
                </Col>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group as={Row} >
                <Form.Label  column sm={3}>Cust Name</Form.Label>
                <Col>
                  <FormControl
                    type="text"
                    placeholder="Search..." 
                    value={custName}               
                    onChange={searchCustName}
                  />
                </Col>
              </Form.Group>
            </Col>
            <Col>
              <Form.Group as={Row} >
                <Form.Label  column sm={3}>Cust HP</Form.Label>
                <Col>
                  <FormControl
                    type="text"
                    placeholder="Search..." 
                    value={phone}               
                    onChange={searchPhone}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row className="mb-3">
            <Col lg={2} md="4" xs="6" className="text-left">
              <Button onClick={handleAddCustomerClick}>Add Customer</Button>
            </Col>
          </Row>
          
          <Row>
            <Col>
              <Table responsive>
                <thead>
                  <tr>
                    <th>Cust ID</th>
                    <th>Old Cust ID</th>
                    <th>Name</th>
                    <th>Gender</th>
                    <th>Phone</th>
                    <th>Email Address</th>
                    <th>DOB</th>
                    <th>Remarks</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody>
                  {customersArray && Array.isArray(customersArray) ? 
                    customersArray.map(item => {
                      return (
                        <tr key={item.id}>
                          <td>{item.id}</td>
                          <td>{item.cust_id}</td>
                          <td>{item.name}</td>
                          <td>{item.gender}</td>
                          <td>{item.phone}</td>
                          <td>{item.email}</td>
                          <td>{DDMMYYYYFormat(item.dob)}</td>
                          <td>{item.remarks}</td>
                          <td>
                            <Link to={`/orders/salesOrder?custId=${item.id}`} className="mr-1"><Button variant="primary">New/Repeat Order</Button></Link>
                            <Button className="mr-1" variant="info" data-value={JSON.stringify(item)} onClick={handleClick}>Edit</Button>
                            {isAdmin() &&
                              <Button variant="danger" data-delete-id={item.id} onClick={handleDeleteClick}>Delete</Button>
                            }
                          </td>
                        </tr>
                      )
                    }) : ''
                  }
                </tbody>
              </Table>  
            </Col>
          </Row>
          {pagination ? 
            <GenericPagination 
              pagination={pagination} 
              rowLimit={rowLimit}
              handleOnPaginationClick={handleOnPaginationClick}
            ></GenericPagination> : <></>}
        </Container>
        }
        <AddCustomer 
          show={showAddCustomer} 
          item={editItem} 
          measurements={measurements}
          finishedGarmentMeasurement={finishedGarmentMeasurement}
          consultantReferralCode={consultantReferralCode}
          handleMeasurementChange={handleMeasurementChange}
          handleStepByStepMeasurementChange={handleStepByStepMeasurementChange}
          handleFinishedGarmentMeasurementChangeByKeyboard={handleFinishedGarmentMeasurementChangeByKeyboard}
          handleFinishedGarmentMeasurementChange={handleFinishedGarmentMeasurementChangeByNumpad}
          handleMeasurementRemarksChange={handleMeasurementRemarksChange}
          handlePostureChange={handlePostureChange}
          handleItemChange={handleItemChange}
          saveEditItem={saveEditItem}
          cancelEditItem={cancelEditItem}
          countryCodes={countryCodes}
          occupations={occupations}
          editCustomer={editCustomer}
          showMeasurements
          setShowFailAlert={setShowFailAlert}
          showFailAlert={showFailAlert}
          failAlertMsg={failAlertMsg}
        ></AddCustomer>


        <StandardModal 
          onConfirm={handleDeleteCustomerConfirm}
          onClose={handleDeleteCustomerClose} 
          show={showModal}
          title="Delete Customer"
          body={``}
        >
         Are you sure you want to delete this Customer ID: {deleteId}?
        </StandardModal>
    </Layout>
  )
}

export default CustomerSummary